import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    titleMain: null,
    subtitleMain: null,

    loadingEvents: false,

    itemsDataEvents: [],

    dateActive: null,
    sizeFontTitle: "32",

    loadingTest: false,

    itemsDataTest: [],

    loadingResults: false,

    itemsDataResults: [],
  },
  getters: {},
  mutations: {
    async getEvents(state, payload) {
      state.loadingEvents = true;
      var vm = this;
      var config = {
        method: "get",
        url: process.env.VUE_APP_URL_API + "events/listSort/1",
        headers: {
          "Content-Type": "application/json",
        },
      };
      await axios(config)
        .then(function (response) {
          state.itemsDataEvents = response.data.data;
          state.loadingEvents = false;
        })
        .catch(function (error) {
          state.loadingEvents = false;
          Vue.swal({
            icon: "error",
            title: "Oops...",
            text: error.response.data.message,
          });
        });
    },

    async getTest(state, payload) {
      state.loadingTest = true;
      var vm = this;
      var config = {
        method: "get",
        url: process.env.VUE_APP_URL_API + "test/listWithTime/" + payload.id,
        headers: {
          "Content-Type": "application/json",
        },
      };
      await axios(config)
        .then(function (response) {
          state.itemsDataTest = response.data.data;

          state.loadingTest = false;
        })
        .catch(function (error) {
          state.loadingTest = false;
          Vue.swal({
            icon: "error",
            title: "Oops...",
            text: error.response.data.message,
          });
        });
    },

    async getResults(state, payload) {
      state.loadingResults = true;
      var vm = this;
      var config = {
        method: "get",
        url:
          process.env.VUE_APP_URL_API +
          `${payload.mode == 2 ? "resultsCampo" : "results"}/${
            payload.mode == 2 ? "listAll" : "listAll"
          }/${payload.mode == 2 ? "m2/" : ""}${payload.id}`,
        headers: {
          "Content-Type": "application/json",
        },
      };
      await axios(config)
        .then(function (response) {
          state.itemsDataResults = response.data.data;
          console.log(state.itemsDataResults[0].results);

          

          state.itemsDataResults[0].results.sort((a, b) => {
            if (a.parcial > b.parcial) {
              return -1;
            }
            if (a.parcial < b.parcial) {
              return 1;
            }
            return 0;
          });

         

          state.loadingResults = false;
        })
        .catch(function (error) {
          
        });
    },
  },
  actions: {
    getEvents({ commit }, payload) {
      return new Promise((resolve, reject) => {
        commit("getEvents", payload);
        resolve();
      });
    },

    getTest({ commit }, payload) {
      return new Promise((resolve, reject) => {
        commit("getTest", payload);
        resolve();
      });
    },

    getResults({ commit }, payload) {
      return new Promise((resolve, reject) => {
        commit("getResults", payload);
        resolve();
      });
    },
  },
  modules: {},
});
