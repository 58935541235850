import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "mainview",

    component: () => import("../views/MainView.vue"),
    children: [
      {
        path: "/",
        name: "mainmenuview",
        component: () => import("../views/menuMainView.vue"),
      },
      {
        path: "/events/main",
        name: "eventsmainview",
        component: () => import("../views/eventsMainView.vue"),
      },
      {
        path: "/calendar/main",
        name: "calendarmainview",
        component: () => import("../views/calendarMainView.vue"),
      },
      {
        path: "/marcasminimas2024/main",
        name: "marcasminimas2024view",
        component: () => import("../views/marcasMinimas2024View.vue"),
      },
      {
        path: "/recordsnationals/main",
        name: "recordsmainview",
        component: () => import("../views/rankingMainView.vue"),
      },
      {
        path: "/test/main/:id/:name",
        name: "testmainview",
        component: () => import("../views/testMainView.vue"),
      },
      {
        path: "/results/main/:id/:name/:id_type_test/:id_test_categorie",
        name: "resultsmainview",
        component: () => import("../views/resultsMainView.vue"),
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
});

export default router;
